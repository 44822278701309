import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Client } from '../../../models/Client';

@Component({
  selector: 'form-request',
  templateUrl: './form-request.component.html',
  styleUrl: './form-request.component.scss',
})
export class FormRequestComponent implements OnInit, OnDestroy {
  @Input() client?: Client;
  alive = true;
  modalActive = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  showModal(): void {
    this.modalActive = true;
  }
}
