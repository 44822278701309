import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiModule } from '../ui/ui.module';
import { UtilsModule } from '../utils/utils.module';

import { PatientPanelComponent } from './components/patient-panel/patient-panel.component';
import { PatientDetailsComponent } from './components/patient-details/patient-details.component';
import { PatientActionsComponent } from './components/patient-actions/patient-actions.component';
import { NoPatientPanelComponent } from './components/no-patient-panel/no-patient-panel.component';
import { PatientNotesComponent } from './components/patient-notes/patient-notes.component';
import { RemindersComponent } from './components/reminders/reminders.component';
import { DateFnsModule } from 'ngx-date-fns';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { PatientHistoryItemComponent } from './components/patient-history-item/patient-history-item.component';
import { ProcedureComponent } from './components/procedure/procedure.component';
import { ProcedureItemComponent } from './components/procedure-item/procedure-item.component';
import { PatientCareplansComponent } from './components/patient-careplans/patient-careplans.component';
import { JoyrideModule } from 'ngx-joyride';
import {LetDirective, PushPipe} from '@ngrx/component';

@NgModule({
  declarations: [PatientPanelComponent, PatientDetailsComponent, PatientActionsComponent, NoPatientPanelComponent, PatientNotesComponent, RemindersComponent, PatientHistoryComponent, PatientHistoryItemComponent, ProcedureComponent, ProcedureItemComponent, PatientCareplansComponent],
  imports: [
    CommonModule,
    DateFnsModule,
    PushPipe,
    LetDirective,
    UiModule,
    UtilsModule,
    JoyrideModule
  ],
  exports: [PatientPanelComponent, NoPatientPanelComponent]
})
export class PatientsModule { }
