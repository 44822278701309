import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Channel } from 'src/app/enums/channel';
import { Client } from 'src/app/models/Client';
import { Conversation } from 'src/app/models/Conversation';
import { User } from 'src/app/models/User';
import { STATE_KEY } from 'src/app/state/reducers';
import { ConversationState } from './reducer';
import { phone } from 'phone';

export const getConversationState = createFeatureSelector<ConversationState>(STATE_KEY.CONVERSATION);

export const getConversation = createSelector(
    getConversationState,
    (state: ConversationState) => state.conversation,
);

export const getExternalConversationUser = createSelector(
    getConversation,
    (conversation: Conversation | null) => {
        if (conversation && conversation.users) {
            const user = conversation.users.find((user) => user.channel !== Channel.INTERNAL);
            if (user) {
                return user;
            }

            return null;
        }

        return null;
    },
);

export const getStaffComments = createSelector(
    getConversationState,
    (state: ConversationState) => state.staffComments,
);

export const getStaffCommentsReversed = createSelector(
    getConversationState,
    (state: ConversationState) => state.staffComments.slice().reverse()
);

export const getConversationStatuses = createSelector(
    getConversationState,
    (state: ConversationState) => state.conversationStatuses,
);

export const getConversationClient = createSelector(
    getConversationState,
    (state: ConversationState) => state.client,
);

export const getConversationPatient = createSelector(
    getConversationState,
    (state: ConversationState) => state.patient,
);

export const getNewConversationPatients = createSelector(
  getConversationState,
  (state: ConversationState) => state.newConversationPatients,
);

export const getClientLoading = createSelector(
    getConversationState,
    (state: ConversationState) => state.clientLoading,
);

export const isClientShared = createSelector(
    getConversationState,
    (state: ConversationState) => state.clientShared,
);

export const isPatientShared = createSelector(
    getConversationState,
    (state: ConversationState) => state.patientShared,
);

export const isPatientLoading = createSelector(
    getConversationState,
    (state: ConversationState) => state.patientLoading,
);

export const isPatientHistoryLoading = createSelector(
    getConversationState,
    (state: ConversationState) => state.patientHistoryLoading,
);

export const getPatientHistory = createSelector(
    getConversationState,
    (state: ConversationState) => state.patientHistory,
);

export const getNewConversationError = createSelector(
    getConversationState,
    (state: ConversationState) => state.newConversationError,
);

export const getPreviousConversations = createSelector(
  getConversationState,
  (state: ConversationState) => state.previousConversations,
);

export const getAllPreviousConversationsLoaded = createSelector(
    getConversationState,
    (state: ConversationState) => state.allPreviousConversationsLoaded,
  );

export const getClientPaymentHistory = createSelector(
  getConversationState,
  (state: ConversationState) => {
    if (state.client && state.client.paymentHistory) {
      const sortable = [...state.client.paymentHistory];
      return sortable.sort((a, b) => {
        return b.createdAt.getTime() - a.createdAt.getTime();
      });
    }

    return state.client?.paymentHistory;
  }
);

export const isUserClientMismatch = createSelector(
    getConversationClient,
    getExternalConversationUser,
    (client: Client | null, user: User | null) => {
        if (client && user && user.phone) {
            let contactsMatch = false;
            const phoneResult = phone(user.phone);

            if (phoneResult.isValid) {
              contactsMatch = !!(client.mobileContacts &&
                client.mobileContacts.find((contact) =>
                  contact.value.replace(/ /g, '') === phoneResult.phoneNumber ||
                  contact.value.replace(/ /g, '') === phoneResult.phoneNumber.replace(phoneResult.countryCode, '0')
                )
              );
            } else {
              contactsMatch = !!(client.mobileContacts &&
                client.mobileContacts.find(
                (contact) => contact.value.replace(/ /g, '') === user.phone.replace(/ /g, '')
                )
              );
            }

            return !contactsMatch;
        }

        return null;
    },
);

export const isReloadingClient = createSelector(
  getConversationState,
  (state: ConversationState) => state.reloadingClient,
);

export const getSearchedConversations = createSelector(
  getConversationState,
  (state: ConversationState) => state.searchedConversations,
);

export const getTotalSearchedConversationsCount = createSelector(
  getConversationState,
  (state: ConversationState) => state.totalSearchedConversations,
);

export const getIsConversationSearchLoading = createSelector(
  getConversationState,
  (state: ConversationState) => state.searchLoading,
);

export const getIsConversationPdfDownloadInProgress = createSelector(
  getConversationState,
  (state: ConversationState) => state.pdfDownloadInProgress,
);

export const getIsBlockSenderOpen = createSelector(
  getConversationState,
  (state: ConversationState) => state.blockSenderOpen,
);

export const getIsBlockSenderLoading = createSelector(
  getConversationState,
  (state: ConversationState) => state.blockSenderLoading,
);

export const isTagsLoading = createSelector(
  getConversationState,
  (state: ConversationState) => state.tagsLoading,
);


