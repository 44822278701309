import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormSubmission } from '../../interfaces/form-submission';
import { Client } from '../../../models/Client';

@Component({
  selector: 'form-submission-list-item',
  templateUrl: './form-submission-list-item.component.html',
  styleUrls: ['./form-submission-list-item.component.scss'],
})
export class FormSubmissionListItemComponent {
  @Input() formSubmission?: FormSubmission;
  @Input() client?: Client;
  actionsOpen = false;

  constructor(private router: Router) {}

  toggleActions(): void {
    this.actionsOpen = !this.actionsOpen;
  }

  viewSub(): void {
    if (this.formSubmission) {
      this.router.navigateByUrl(
        `/forms/submissions/${this.formSubmission.uuid}`
      );
    }
  }
}
