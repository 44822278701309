import * as actions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { Conversation } from 'src/app/models/Conversation';
import { Comment } from 'src/app/models/Comment';
import { ConversationStatus } from 'src/app/models/ConversationStatus';
import { Client } from 'src/app/models/Client';
import { Patient } from 'src/app/models/Patient';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { tourComments, tourConversation } from '../../constants/tour.constants';
import { NewConversationError } from 'src/app/interfaces/new-conversation-error';
import { CampaignMessage } from 'src/app/models/CampaignMessage';
import {ReadStatus} from '../../enums/read-status';
import { User } from '../../models/User';

export interface ConversationState {
  conversation: Conversation | null;
  currentConversationId: string | null;
  previousConversations: Array<Conversation|CampaignMessage>;
  allPreviousConversationsLoaded: boolean;
  staffComments: Comment[];
  conversationStatuses: ConversationStatus[];
  client: Client | null;
  patient: Patient | null;
  clientLoading: boolean;
  newConversationPatients: Patient[];
  patientLoading: boolean;
  clientShared: boolean;
  patientShared: boolean;
  patientHistoryLoading: boolean;
  patientHistory: HistoryItem[];
  newConversationError: NewConversationError | null;
  reloadingClient: boolean;
  searchedConversations: Conversation[];
  totalSearchedConversations: number;
  searchLoading: boolean;
  pdfDownloadInProgress: boolean;
  blockSenderLoading: boolean;
  blockSenderOpen: boolean;
  tagsLoading: boolean;
}

export const initialState: ConversationState = {
  conversation: null,
  currentConversationId: null,
  previousConversations: [],
  allPreviousConversationsLoaded: false,
  staffComments: [],
  conversationStatuses: [],
  client: null,
  patient: null,
  clientLoading: false,
  patientLoading: false,
  clientShared: false,
  patientShared: false,
  newConversationPatients:[],
  patientHistoryLoading: false,
  patientHistory: [],
  newConversationError: null,
  reloadingClient: false,
  searchedConversations: [],
  totalSearchedConversations: 0,
  searchLoading: false,
  pdfDownloadInProgress: false,
  blockSenderLoading: false,
  blockSenderOpen: false,
  tagsLoading: false,
};

const conversationReducer = createReducer(
  initialState,
  on(actions.SetCurrentConversation, (state, payload) => ({
    ...state,
    currentConversationId: payload.id
  })),
  on(actions.ClearCurrentConversation, (state, payload) => ({
    ...state,
    currentConversationId: null
  })),
  on(actions.GetConversationSuccess, (state, payload) => {
    if (state.currentConversationId === payload.conversation.id) {
      return {
        ...state,
        conversation: payload.conversation
      };
    }

    return {
      ...state
    };
  }),
  on(actions.GetPreviousConversationsSuccess, (state, payload) => ({
    ...state,
    previousConversations: [
      ...state.previousConversations,
      ...payload.previousConversations
    ]
  })),
  on(actions.SetPreviousConversationsLoaded, (state, payload) => ({
    ...state,
    allPreviousConversationsLoaded: payload.loaded
  })),
  on(actions.GetStaffCommentsSuccess, (state, payload) => ({
    ...state,
    staffComments: payload.comments
  })),
  on(actions.SetLastResponseAtToNow, (state) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          lastResponseAt: new Date()
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.GetConversationStatusesSuccess, (state, payload) => ({
    ...state,
    conversationStatuses: payload.statuses
  })),
  on(actions.SetConversationStatus, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          status: payload.status
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetConversationOwner, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          owner: payload.owner
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetConversationAssignee, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          assignee: payload.assignee
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.UpdateConversationSummary, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          summary: payload.summary
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetConversationAsResolved, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          resolvedAt: new Date()
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetConversationAsRead, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          newMessage: 0
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.ClearClient, (state, payload) => ({
    ...state,
    client: null
  })),
  on(actions.ClearPatient, (state, payload) => ({
    ...state,
    patient: null
  })),
  on(actions.ClearConversation, (state, payload) => ({
    ...state,
    conversation: null
  })),
  on(actions.ClearPreviousConversations, (state, payload) => ({
    ...state,
    previousConversations: [],
    allPreviousConversationsLoaded: false,
  })),
  on(actions.ClearStaffComments, (state, payload) => ({
    ...state,
    staffComments: []
  })),
  on(actions.GetClientSuccess, (state, payload) => {
    if (payload.client.id === state.conversation?.client?.id) {
      return {
        ...state,
        client: payload.client,
        clientLoading: false
      };
    }
    return {
      ...state,
      clientLoading: false
    };
  }),
  on(actions.GetClientPaymentHistorySuccess, (state, payload) => {
    if (state.client) {
      return ({
        ...state,
        client: {
          ...state.client,
          paymentHistory: payload.payments
        },
      });
    } else {
      return ({
        ...state
      });
    }
  }),
  on(actions.GetClientFailed, (state, payload) => ({
    ...state,
    clientLoading: false
  })),
  on(actions.StartClientLoading, (state, payload) => ({
    ...state,
    clientLoading: true
  })),
  on(actions.StopClientLoading, (state, payload) => ({
    ...state,
    clientLoading: false
  })),
  on(actions.ResetShareClientToPms, (state, payload) => ({
    ...state,
    clientShared: false
  })),
  on(actions.ShareClientToPmsSuccess, (state, payload) => ({
    ...state,
    clientShared: true
  })),
  on(actions.ResetSharePatientToPms, (state, payload) => ({
    ...state,
    patientShared: false
  })),
  on(actions.SharePatientToPmsSuccess, (state, payload) => ({
    ...state,
    patientShared: true
  })),
  on(actions.GetPatient, (state, payload) => ({
    ...state,
    patientLoading: true
  })),
  on(actions.GetPatientSuccess, (state, payload) => {
    if (payload.patient.id === state.conversation?.patient?.id) {
      return {
        ...state,
        patient: payload.patient,
        patientLoading: false
      };
    }
    return {
      ...state,
      patientLoading: false
    };
  }),
  on(actions.GetNewConversationPatientsSuccess, (state, payload) => {
    if (payload.replace) {
      return {
        ...state,
        newConversationPatients: payload.patients,
        patientsLoading: false,
      };
    }
    return {
      ...state,
      newConversationPatients: [
        ...state.newConversationPatients,
        ...payload.patients
      ],
      patientsLoading: false,
    };
  }),
  on(actions.AddCommentSuccess, (state, payload) => ({
    ...state,
    staffComments: [
      ...state.staffComments,
      payload.comment
    ]
  })),
  on(actions.GetPatientHistory, (state, payload) => ({
    ...state,
    patientHistoryLoading: true
  })),
  on(actions.GetPatientHistorySuccess, (state, payload) => ({
    ...state,
    patientHistoryLoading: false,
    patientHistory: payload.historyItems
  })),
  on(actions.ClearPatientHistory, (state, payload) => ({
    ...state,
    patientHistory: []
  })),
  on(actions.UpdateUser, (state, payload) => {
    if (state.conversation && state.conversation.users) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          users: state.conversation.users.map(user => {
            if (user.id === payload.user.id) {
              const newUser = {...user};
              newUser.firstName = payload.user.firstName;
              newUser.lastName = payload.user.lastName;
              newUser.fullName = `${payload.user.firstName} ${payload.user.lastName}`;
              return newUser;
            }

            return user;
          })
        }
      };
    }

    return {...state};
  }),
  on(actions.UpdateUserSuccess, (state, payload) => {
    if (state.conversation && state.conversation.users) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          users: state.conversation.users.map(user => {
            if (user.id === payload.user.id) {
              const newUser = {...user};
              newUser.firstName = payload.user.firstName;
              newUser.lastName = payload.user.lastName;
              newUser.fullName = `${payload.user.firstName} ${payload.user.lastName}`;
              return newUser;
            }

            return user;
          })
        }
      };
    }

    return {...state};
  }),
  on(actions.UpdateClinicResponseToNow, (state, payload) => {
    if (state.conversation) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          lastClinicResponseAt: new Date(),
          timeSinceLastClinicResponse: 1
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.AddTourConversation, (state, payload) => ({
    ...state,
    conversation: tourConversation
  })),
  on(actions.AddTourStaffComments, (state, payload) => ({
    ...state,
    staffComments: tourComments
  })),
  on(actions.NewConversationError, (state, payload) => ({
    ...state,
    newConversationError: {
      errorMessage: payload.errorMessage,
      existingConversation: payload.existingConversation ?? undefined
    }
  })),
  on(actions.ClearNewConversationError, (state) => ({
    ...state,
    newConversationError: null
  })),
  on(actions.AddWatcher, (state, payload) => {
    if (state.conversation && state.conversation.watchers) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          watchers: [
            ...state.conversation.watchers,
            payload.user,
          ]
        }
      };
    }

    return {...state};
  }),
  on(actions.RemoveWatcher, (state, payload) => {
    if (state.conversation && state.conversation.watchers) {
      return {
        ...state,
        conversation: {
          ...state.conversation,
          watchers: state.conversation.watchers.filter(watcher => watcher.id !== payload.user.id)
        }
      };
    }

    return {...state};
  }),
  on(actions.RefreshConversationClient, (state, payload) => {
    return {
      ...state,
      reloadingClient: true
    };
  }),
  on(actions.RefreshClientComplete, (state, payload) => {
    return {
      ...state,
      reloadingClient: false
    };
  }),
  on(actions.SearchConversations, (state, payload) => {
    return {
      ...state,
      searchLoading: true,
    };
  }),
  on(actions.SearchConversationsSuccess, (state, payload) => {
    return {
      ...state,
      searchLoading: false,
      searchedConversations: payload.conversations,
      totalSearchedConversations: payload.total
    };
  }),
  on(actions.MarkConversationsAsUnreadSuccess, (state, payload) => {
    return {
      ...state,
      searchedConversations: [
        ...state.searchedConversations.map((conversation) => {
          if (payload.conversations.find((payloadConversation) => payloadConversation.id === conversation.id)) {
            return {
              ...conversation,
              readStatus: ReadStatus.UNREAD,
            };
          } else {
            return conversation;
          }
        })
      ],
    };
  }),
  on(actions.MarkConversationsAsReadSuccess, (state, payload) => {
    return {
      ...state,
      searchedConversations: [
        ...state.searchedConversations.map((conversation) => {
          if (payload.conversations.find((payloadConversation) => payloadConversation.id === conversation.id)) {
            return {
              ...conversation,
              readStatus: ReadStatus.READ,
            };
          } else {
            return conversation;
          }
        })
      ],
    };
  }),
  on(actions.ExportConversationsAsPdfs, (state, payload) => {
    return {
      ...state,
      pdfDownloadInProgress: true
    };
  }),
  on(actions.ExportConversationsAsPdfsComplete, (state, payload) => {
    return {
      ...state,
      pdfDownloadInProgress: false
    };
  }),
  on(actions.BlockSenderOpen, (state, payload) => {
    return {
      ...state,
      blockSenderOpen: true
    };
  }),
  on(actions.BlockSenderClose, (state, payload) => {
    return {
      ...state,
      blockSenderOpen: false
    };
  }),
  on(actions.BlockSender, (state, payload) => {
    return {
      ...state,
      blockSenderLoading: true
    };
  }),
  on(actions.BlockSenderSuccess, (state, payload) => {
    return {
      ...state,
      blockSenderLoading: false,
      blockSenderOpen: false
    };
  }),
  on(actions.SetTagsOnConversation, (state, payload) => {
    if (state.currentConversationId === payload.conversation.id) {
      return {
        ...state,
        tagsLoading: true,
        conversation: {
          ...payload.conversation,
          tags: [
            ...payload.tags,
          ]
        }
      };
    }

    return {
      ...state
    };
  }),
  on(actions.SetTagsOnConversationSuccess, (state) => {
    return {
      ...state,
      tagsLoading: false
    };
  }),
  on(actions.SetTagsOnConversationFailed, (state, payload) => {
    if (state.currentConversationId === payload.conversation.id) {
      return {
        ...state,
        tagsLoading: false,
        conversation: {
          ...payload.conversation,
        }
      };
    }

    return {
      ...state,
      tagsLoading: false,
    };
  })
);

export function reducer(state: ConversationState | undefined, action: Action): ConversationState {
  return conversationReducer(state, action);
}
