import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Observable} from 'rxjs';
import {Practice} from '../../../models/Practice';
import {Media} from '../../../models/Media';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../state/reducers';
import {
  getMedia,
  getMediaLoading,
  getTotalMediaCount
} from '../../state/selectors';
import {takeWhile} from 'rxjs/operators';
import {getCurrentPractice} from '../../../practices/state/selectors';
import {GetMedia, SetCurrentMediaBrowser} from '../../state/actions';
import {MediaType} from '../../../enums/media-type';
import { MediaBrowser } from '../../../enums/media-browsers';

@Component({
  selector: 'app-media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent implements OnInit, OnDestroy {
  type?: MediaType;
  alive = true;
  manageMedia: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private store: Store<AppState>) {
    this.type = this.config.data.type;
    this.manageMedia = this.config.data.manageMedia;
  }

  ngOnInit(): void {
    this.store.dispatch(SetCurrentMediaBrowser({browser: MediaBrowser.SELECTOR}));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  handleSelect(media: Media): void {
    this.ref.close(media);
  }
}
