import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../ui/ui.module';
import {NewFormRequestComponent} from './components/new-form-request/new-form-request.component';
import {PaginatorModule} from 'primeng/paginator';
import {PatientsModule} from '../patients/patients.module';
import {ReactiveFormsModule} from '@angular/forms';
import { FormsPage } from './pages/forms/forms.component';
import {FormsRoutingModule} from './forms-routing.module';
import {PushPipe} from '@ngrx/component';
import { FormBuilderPage } from './pages/form-builder/form-builder.component';
import {UtilsModule} from '../utils/utils.module';
import { FormListItemComponent } from './components/form-list-item/form-list-item.component';
import {DirectivesModule} from '../directives/directives.module';
import {FormSubmissionsPage} from './pages/form-submissions/form-submissions.component';
import {FormSubmissionPage} from "./pages/form-submission/form-submission.component";

@NgModule({
  declarations: [
    NewFormRequestComponent,
    FormsPage,
    FormBuilderPage,
    FormListItemComponent,
    FormSubmissionsPage,
    FormSubmissionPage,
  ],
  imports: [
    FormsRoutingModule,
    CommonModule,
    DirectivesModule,
    UiModule,
    UtilsModule,
    PaginatorModule,
    PatientsModule,
    ReactiveFormsModule,
    PushPipe
  ],
  exports: [NewFormRequestComponent, ]
})
export class FormsModule { }
