import { Component, Input } from '@angular/core';
import { Media } from '../../../models/Media';
import { ConversationService } from '../../services/conversation.service';
  
  @Component({
    selector: 'conversation-preview',
    templateUrl: './conversation-preview.component.html',
    styleUrls: ['./conversation-preview.component.scss'],
  })
  export class ConversationMessagePreviewComponent {
    constructor(private conversationService: ConversationService) {}
  
    @Input() selectedMedia: Media | undefined = undefined;
    @Input() previewType: string | null = null;
    @Input() previewOpen = false;
    @Input() device = 'desktop';
    quickReply = this.conversationService.quickReply;
    quickReply2 = this.conversationService.quickReply2;
    linkButton = this.conversationService.linkButton;
    initialMessage = this.conversationService.initialMessage;
    signedUrl: string | null = null;

  ngOnChanges() {
    this.signedUrl = null;
    setTimeout(() => {
      if (this.selectedMedia) {
        this.signedUrl = this.selectedMedia.signedUrl;
      }
    }, 2000);
    }

  get formattedMessage(): string {
    return this.initialMessage.value ? this.initialMessage.value.replace(/\n/g, '<br>') : '';
  }
}