import { createAction, props } from '@ngrx/store';
import { HistoryItem } from 'src/app/models/HistoryItem';
import { Client } from 'src/app/models/Client';
import { Comment } from 'src/app/models/Comment';
import { Conversation } from 'src/app/models/Conversation';
import { ConversationStatus } from 'src/app/models/ConversationStatus';
import { Patient } from 'src/app/models/Patient';
import { User } from 'src/app/models/User';
import { Contact } from '../../models/Contact';
import { Practice } from '../../models/Practice';
import { FullPayment } from '../../models/FullPayment';
import { Group } from '../../models/Group';
import { Payment } from '../../models/Payment';
import { Channel } from '../../enums/channel';
import {ConversationsFilterDto} from '../../interfaces/dto/conversations-filter.dto';
import { CampaignMessage } from 'src/app/models/CampaignMessage';
import {Tag} from "../../models/Tag";
import {Template} from "../../models/Template";
import { Media } from '../../models/Media';
import {TemplateMergeFieldDo} from "../../interfaces/template-merge-field.do.interface";

export const SetCurrentConversation = createAction(
    '[Conversation] Set Current Conversation',
    props<{id: string}>()
);

export const ClearCurrentConversation = createAction(
    '[Conversation] Clear Current Conversation'
);

export const GetConversationViewersSuccess = createAction(
    '[Conversation] Get Conversation Viewers Success',
    props<{viewers: User[]}>()
);

export const GetConversation = createAction(
    '[Conversation] Get Conversation',
    props<{id: string}>()
);

export const GetConversationSuccess = createAction(
    '[Conversation] Get Conversation - Success',
    props<{conversation: Conversation}>()
);

export const GetPreviousConversations = createAction(
  '[Conversation] Get Previous Conversations',
  props<{conversationId: string, skip: number}>()
);

export const GetMoreNewConversationPatients = createAction(
  '[Products Requests] Get More Product Request Patients',
  props<{page: number,client :Client}>()
);

export const GetPreviousConversationsSuccess = createAction(
  '[Conversation] Get Previous Conversations - Success',
  props<{previousConversations: Array<Conversation|CampaignMessage>}>()
);

export const SetPreviousConversationsLoaded = createAction(
    '[Conversation] Set Previous Conversations Loaded',
  props<{loaded: boolean}>()
);

export const ClearConversation = createAction(
  '[Conversation] Clear Conversation'
);

export const ClearPreviousConversations = createAction(
  '[Conversation] Clear Previous Conversation'
);

export const NewConversation = createAction(
  '[Conversation] New Conversation',
  props<{
    practice: Practice,
    channel: Channel,
    client: Client | null,
    contact: Contact | null,
    payment: Payment | null,
    initialMessage?: string,
    template?: Template | null,
    mergeFields?: TemplateMergeFieldDo[] | null,
    buttonLink?: string,
    selectedMedia?: Media,
    patient?: Patient | null,
  }>()
);

export const NewConversationError = createAction(
    '[Conversation] New Conversation - Error',
    props<{errorMessage: string, existingConversation?: Conversation}>()
);

export const ClearNewConversationError = createAction(
    '[Conversation] Clear New Conversation Error'
);

export const GetStaffComments = createAction(
    '[Conversation] Get Staff Comments',
    props<{conversationId: string}>()
);

export const GetStaffCommentsSuccess = createAction(
    '[Conversation] Get Staff Comments - Success',
    props<{comments: Comment[]}>()
);

export const SetLastResponseAtToNow = createAction(
    '[Conversation] Set lastResponseAt to Now'
);

export const GetConversationStatuses = createAction(
    '[Conversation] Get Conversation Statuses'
);
export const SetConversationClientSuccess = createAction(
  '[Conversation] Set Conversation Client Success',
  props<{client: Client}>()
);

export const GetConversationStatusesSuccess = createAction(
    '[Conversation] Get Conversation Statuses - Success',
    props<{statuses: ConversationStatus[]}>()
);

export const SetConversationStatus = createAction(
    '[Conversation] Set Conversation Status',
    props<{conversationId: string, status: ConversationStatus}>()
);

export const SetConversationOwner = createAction(
    '[Conversation] Set Conversation Owner',
    props<{conversationId: string, owner: User}>()
);

export const SetConversationAssignee = createAction(
    '[Conversation] Set Conversation Assignee',
    props<{conversationId: string, assignee: User|Group}>()
);

export const UpdateConversationSummary = createAction(
    '[Conversation] Update Conversation Summary',
    props<{conversationId: string, summary: string}>()
);

export const SetConversationAsResolved = createAction(
    '[Conversation] Set Conversation as Resolved',
    props<{conversationId: string, saveToPms: boolean, downloadPdf: boolean}>()
);
export const SetCreateConversationClient = createAction(
  '[Conversation] Set Conversation Client',
  props<{client?: Client}>()
);

export const SetConversationAsRead = createAction(
  '[Conversation] Set Conversation as Read',
  props<{conversationId: string}>()
);

export const GetClient = createAction(
    '[Conversation] Get Client',
    props<{clientId: string}>()
);

export const GetClientSuccess = createAction(
    '[Conversation] Get Client - Success',
    props<{client: Client}>()
);

export const GetClientPaymentHistory = createAction(
  '[Conversation] Get Client Payment History',
  props<{clientId: string}>()
);

export const GetClientPaymentHistorySuccess = createAction(
  '[Conversation] Get Client Payment History Success',
  props<{payments: FullPayment[]}>()
);

export const GetClientFailed = createAction(
    '[Conversation] Get Client - Fail'
);

export const ClearClient = createAction(
    '[Conversation] Clear Client'
);

export const GetPatient = createAction(
    '[Conversation] Get Patient',
    props<{patientId: string}>()
);

export const GetPatientSuccess = createAction(
    '[Conversation] Get Patient - Success',
    props<{patient: Patient}>()
);

export const GetNewConversationPatientsSuccess = createAction(
  '[Conversation] Get Patients - Success',
  props<{patients: Patient[], replace: boolean}>()
);

export const SetNewConversationClient = createAction(
    '[Conversation] Set New Conversation Client',
    props<{client: Client}>()
);

export const ClearPatient = createAction(
    '[Conversation] Clear Patient'
);

export const ClearStaffComments = createAction(
  '[Conversation] Clear Staff Comments'
);

export const SetConversationClient = createAction(
    '[Conversation] Set Conversation Client',
    props<{client: Client}>()
);

export const RefreshConversationClient = createAction(
  '[Conversation] Refresh Conversation Client',
  props<{conversationId: string}>()
);

export const RefreshClientComplete = createAction(
  '[Conversation] Refresh Conversation Client Complete',
);

export const ResetShareClientToPms = createAction(
    '[Conversation] Reset Share Client to PMS'
);

export const ShareClientToPms = createAction(
    '[Conversation] Share Client to PMS',
    props<{client: Client}>()
);

export const ShareClientToPmsSuccess = createAction(
    '[Conversation] Share Client to PMS - Success'
);

export const ResetSharePatientToPms = createAction(
    '[Conversation] Reset Share Patient to PMS'
);

export const SharePatientToPms = createAction(
    '[Conversation] Share Patient to PMS',
    props<{patient: Patient}>()
);

export const SharePatientToPmsSuccess = createAction(
    '[Conversation] Share Patient to PMS - Success'
);

export const StartClientLoading = createAction(
    '[Conversation] Start Client Loading'
);

export const StopClientLoading = createAction(
    '[Conversation] Stop Client Loading'
);

export const SetConversationPatient = createAction(
    '[Conversation] Set Conversation Patient',
    props<{patient: Patient}>()
);
export const SetNewConversationPatient = createAction(
  '[Conversation] Set New Conversation Patient',
  props<{patient: Patient}>()
);

export const RemoveClientFromConversation = createAction(
    '[Conversation] Remove Client From Conversation',
    props<{conversationId: string}>()
);

export const RemovePatientFromConversation = createAction(
    '[Conversation] Remove Patient From Conversation',
    props<{conversationId: string}>()
);

export const AddComment = createAction(
    '[Conversation] Add comment',
    props<{productRequestId?: string; conversationId?: string, message: string}>()
);

export const AddCommentSuccess = createAction(
    '[Conversation] Add comment - Success',
    props<{comment: Comment}>()
);

export const GetPatientHistory = createAction(
    '[Conversation] Get Patient History',
    props<{patientId: string, skip: number, limit: number}>()
);

export const GetPatientHistorySuccess = createAction(
    '[Conversation] Get Patient History - Success',
    props<{historyItems: HistoryItem[]}>()
);

export const ClearPatientHistory = createAction(
    '[Conversation] Clear Patient History'
);

export const UpdateUser = createAction(
    '[Conversation] Update User',
    props<{user: User}>()
);

export const UpdateUserSuccess = createAction(
    '[Conversation] Update User - Success',
    props<{user: User}>()
);

export const UpdateClinicResponseToNow = createAction(
    '[Conversation] Update Clinic Response At to Now'
);

export const AddTourConversation = createAction(
  '[Conversation] Add tour conversation',
  props<{user: User}>()
);

export const AddTourStaffComments = createAction(
  '[Conversation] Add tour staff comments',
);

export const AddWatcher = createAction(
    '[Conversation] Add Watcher',
    props<{conversation: Conversation, user: User}>()
);

export const RemoveWatcher = createAction(
    '[Conversation] Remove Watcher',
    props<{conversation: Conversation, user: User}>()
);

export const WatcherAdded = createAction(
    '[Conversation] Watcher Added'
);

export const WatcherRemoved = createAction(
    '[Conversation] Watcher Removed'
);

export const UpdateConversationsFilters = createAction(
  '[Conversation] Update Conversation Filters',
  props<{filters: ConversationsFilterDto}>()
);

export const SearchConversations = createAction(
  '[Conversation] Search Conversations',
  props<{filters: ConversationsFilterDto}>()
);

export const SearchConversationsSuccess = createAction(
  '[Conversation] Search conversations success',
  props<{conversations: Conversation[], total: number}>()
);

export const MarkConversationsAsRead = createAction(
  '[Conversation] Mark conversations as read',
  props<{conversations: Conversation[]}>()
);

export const MarkConversationsAsUnread = createAction(
  '[Conversation] Mark conversations as unread',
  props<{conversations: Conversation[]}>()
);

export const MarkConversationsAsReadSuccess = createAction(
  '[Conversation] Mark conversations as read success',
  props<{conversations: Conversation[]}>()
);

export const MarkConversationsAsUnreadSuccess = createAction(
  '[Conversation] Mark conversations as unread success',
  props<{conversations: Conversation[]}>()
);

export const MarkConversationsAsUpdated = createAction(
  '[Conversation] Mark conversations as updated',
);

export const ExportConversationsAsPdfs = createAction(
  '[Conversation] Export Conversations As PDFs',
  props<{conversations: Conversation[]}>()
);

export const ExportConversationsAsPdfsComplete = createAction(
  '[Conversation] Export Conversations As PDFs Complete',
);

export const ExportConversationAsPdf = createAction(
  '[Conversation] Export Conversation As PDF',
  props<{conversationId: number}>()
);

export const ExportConversationAsPdfComplete = createAction(
  '[Conversation] Export Conversation As PDF Complete',
);

export const BlockSenderOpen = createAction(
  '[Conversation] Open block sender'
);

export const BlockSenderClose = createAction(
  '[Conversation] Close block sender'
);

export const BlockSender = createAction(
  '[Conversation] Block sender',
  props<{conversations: Conversation[]}>()
);

export const BlockSenderSuccess = createAction(
  '[Conversation] Block sender success'
);

export const ConvertConversationToSMS = createAction(
  '[Conversation] Convert to SMS',
  props<{conversation: Conversation, resendAll: boolean}>()
);

export const ConvertConversationToSMSSuccess = createAction(
  '[Conversation] Convert to SMS Success',
  props<{conversation: Conversation}>()
);

export const SetTagsOnConversation = createAction(
  '[Conversation] Set tags on conversation',
  props<{tags: Tag[], conversation: Conversation}>()
);

export const SetTagsOnConversationSuccess = createAction(
  '[Conversation] Set tags on conversation success',
  props<{tags: Tag[], conversation: Conversation}>()
);

export const SetTagsOnConversationFailed = createAction(
  '[Conversation] Set tags on conversation failed',
  props<{tags: Tag[], conversation: Conversation}>()
);
