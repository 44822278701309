<ng-container *ngIf="formSubmission">
  <div class="form-list-item">
    <div class="form-name">
      <span class="label">Form:</span>{{ formSubmission.form.name }}
    </div>
    <ng-container *ngIf="!client?.pmsId">
      <div class="client-name">
        <span class="label">Client:</span
        >{{ formSubmission.clientName || "Unmatched" }}
      </div>
      <div class="created">
        <span class="label">Sent:</span
        >{{ formSubmission.createdAt | date : "dd/MM/yy 'at' h:mma" }}
      </div>
    </ng-container>
    <div class="completed">
      <div class="capsule">
        <ng-container *ngIf="!formSubmission.completedAt">
          <capsule
            label="Pending"
            backgroundColor="var(--orange-400)"
            [small]="true"
          ></capsule>
        </ng-container>
        <ng-container *ngIf="formSubmission.completedAt">
          <capsule
            label="Complete"
            backgroundColor="var(--success-color)"
            [small]="true"
          ></capsule>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!client?.pmsId">
    <div class="actions" >
      <div
        class="actions-opener"
        (clickOutside)="actionsOpen = false"
        (click)="toggleActions()"
      >
        <img src="assets/images/icons/dots.svg" alt="" />
      </div>
      <ng-container *ngIf="actionsOpen">
        <div class="actions-dropdown">
          <div class="action" (click)="viewSub()">View</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  </div>
</ng-container>
