<div class="preview-title">
  Message preview
</div>
<div class="message-preview-wrap">
  <div class="message-preview">
    <ng-container *ngIf="selectedMedia && previewType === 'pdf'">
      <div class="pdf-preview">
        <i class="pi pi-file-pdf"></i><span class="link-text">{{ selectedMedia.name || 'Download PDF' }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedMedia && previewType === 'image' && selectedMedia.signedUrl">
      <div class="image-preview">
        <img [src]="selectedMedia.signedUrl" />
      </div>
    </ng-container>
    <ng-container *ngIf="selectedMedia && previewType === 'video' && selectedMedia.signedUrl  && signedUrl">
      <div class="video-preview">
        <video [controls]="false" preload="metadata">
          <source [src]="signedUrl">
        </video>
      </div>
    </ng-container>
    <div [innerHTML]="formattedMessage"></div>
  </div>
</div>

<message-button [value]="linkButton.value" icon="pi pi-external-link"></message-button>
<message-button [value]="quickReply.value" icon="pi pi-reply"></message-button>
<message-button [value]="quickReply2.value" icon="pi pi-reply"></message-button>