import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateFnsModule } from 'ngx-date-fns';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

import { CapsuleComponent } from './components/capsule/capsule.component';
import { Message } from './components/message/message.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserChangerComponent } from './components/user-changer/user-changer.component';
import {LetDirective, PushPipe} from '@ngrx/component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationComponent } from './components/navigation/navigation.component';
import { RouterModule } from '@angular/router';
import { MessageAttachmentComponent } from './components/message-attachment/message-attachment.component';
import { UtilsModule } from '../utils/utils.module';
import { DirectivesModule } from '../directives/directives.module';
import { TooltipModule } from 'primeng/tooltip';
import { MessageStatusComponent } from './components/message-status/message-status.component';
import { PracticeSelectorComponent } from './components/practice-selector/practice-selector.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsListItemComponent } from './components/notifications-list-item/notifications-list-item.component';
import { NotificationListControlsComponent } from './components/notification-list-controls/notification-list-controls.component';
import { LightswitchComponent } from './components/lightswitch/lightswitch.component';
import { CheckboxCircleComponent } from './components/checkbox-circle/checkbox-circle.component';
import { UserChangerListComponent } from './components/user-changer-list/user-changer-list.component';
import { JoyrideModule } from 'ngx-joyride';
import { DialogNoPracticesComponent } from './components/dialog-no-practices/dialog-no-practices.component';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { MobileHeaderActionBarComponent } from './components/mobile-header-action-bar/mobile-header-action-bar.component';
import { CreateConversationActionBarComponent } from './components/action-bars/create-conversation-action-bar/create-conversation-action-bar.component';
import { ManageConversationActionBarComponent } from './components/action-bars/manage-conversation-action-bar/manage-conversation-action-bar.component';
import { NewConversationComponent } from './components/new-conversation/new-conversation.component';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ClientSelectorResultsListComponent } from './components/client-selector-results-list/client-selector-results-list.component';
import { ClientListItemComponent } from './components/client-list-item/client-list-item.component';
import { PaymentRequestComponent } from './components/payment-request/payment-request.component';
import { WatchersComponent } from './components/watchers/watchers.component';
import { WatcherContextMenuComponent } from './components/watcher-context-menu/watcher-context-menu.component';
import { DialogNoConnectionComponent } from './components/dialog-no-connection/dialog-no-connection.component';
import { QuillModule } from 'ngx-quill';
import MagicUrl from 'quill-magic-url';
import { ConversationResolverComponent } from './components/conversation-resolver/conversation-resolver.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownIconComponent } from './components/icons/dropdown-icon/dropdown-icon.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { CreateActionsComponent } from './components/create-actions/create-actions.component';
import { NewPaymentComponent } from './components/new-payment/new-payment.component';
import { PaymentRequestFormComponent } from './components/payment-request-form/payment-request-form.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CashRegisterIconComponent} from './components/icons/cash-register-icon/cash-register-icon.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CampaignAddFormComponent} from './components/campaign-add-form/campaign-add-form.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ConversationBulkActionsComponent } from './components/conversation-bulk-actions/conversation-bulk-actions.component';
import { WhatsNewDialogComponent } from './components/whats-new-dialog/whats-new-dialog.component';
import { DialogTemplateSelectorComponent } from './components/dialog-template-selector/dialog-template-selector.component';
import { ClientAvatarComponent } from './components/client-avatar/client-avatar.component';
import { BlockSenderComponent } from './components/block-sender/block-sender.component';
import { SplitPanelButtonComponent } from './components/split-panel-button/split-panel-button.component';
import { VerticalSplitComponent } from './components/icons/vertical-split/vertical-split.component';
import { ProductRequestHistoryComponent } from './components/product-request-history/product-request-history.component';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { FilterItemMultipleComponent } from './components/filter-item-multiple/filter-item-multiple.component';
import {DragDropModule} from 'primeng/dragdrop';
import { FilterCreatedAtComponent } from './components/filter-created-at/filter-created-at.component';
import { FilterStatusComponent } from './components/filter-status/filter-status.component';
import { FilterClientComponent } from './components/filter-client/filter-client.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FilterItemTypeaheadComponent } from './components/filter-item-typeahead/filter-item-typeahead.component';
import { FilterOwnerComponent } from './components/filter-owner/filter-owner.component';
import { FilterApprovalStatusComponent } from './components/filter-approval-status/filter-approval-status.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { ToastSaveConversationPmsErrorComponent } from './components/toast-save-conversation-pms-error/toast-save-conversation-pms-error.component';
import { TagAvatarComponent } from './components/tag-avatar/tag-avatar.component';
import {ClientDetailsComponent} from './components/client-details/client-details.component';
import {ClientPanelComponent} from './components/client-panel/client-panel.component';
import {ClientActionsComponent} from './components/client-actions/client-actions.component';
import {ClientNotRecognisedComponent} from './components/client-not-recognised/client-not-recognised.component';
import {NoClientPanelComponent} from './components/no-client-panel/no-client-panel.component';
import {FinancialNotesComponent} from './components/financial-notes/financial-notes.component';
import {ClientNotesComponent} from './components/client-notes/client-notes.component';
import {PaymentHistoryComponent} from './components/payment-history/payment-history.component';
import { FilterActiveDepositComponent } from './components/filter-active-deposit/filter-active-deposit.component';
import { NewClientDepositComponent } from './components/new-client-deposit/new-client-deposit.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ReplacePlaceholderComponent } from './components/replace-placeholder/replace-placeholder.component';
import { DialogMessagePreviewComponent } from './components/dialog-message-preview/dialog-message-preview.component';
import { FormSelectorComponent } from './components/form-selector/form-selector.component';
import { ConversationMessagePreviewComponent } from './components/conversation-preview/conversation-preview.component';
import { MessageButtonComponent} from './components/message-button/message-button.component';
import { ConversationService } from './services/conversation.service';
import { ThemeIconUrlPipe } from '../pipes/theme-icon-url.pipe';
import { ThemeSvgColorPipe } from '../pipes/theme-svg-color.pipe';
import { PatientSelectorComponent } from '../patients/components/patient-selector/patient-selector.component';
import { PatientListItemComponent } from '../patients/components/patient-list-item/patient-list-item.component';
import { SafeUrlPipe } from '../pipes/generate-safe-url.pipe';
import { FormRequestComponent } from './components/form-request/form-request.component';
import { FormSubmissionListComponent } from '../forms/components/form-submission-list/form-submission-list.component';
import { FormSubmissionListItemComponent } from '../forms/components/form-submission-list-item/form-submission-list-item.component';

@NgModule({
  declarations: [
    UserAvatarComponent,
    ClientAvatarComponent,
    CapsuleComponent,
    Message,
    UserChangerComponent,
    PatientSelectorComponent,
    PatientListItemComponent,
    NavigationComponent,
    MessageAttachmentComponent,
    MessageStatusComponent,
    PracticeSelectorComponent,
    DialogNoPracticesComponent,
    MobileHeaderComponent,
    DesktopHeaderComponent,
    MobileHeaderActionBarComponent,
    CreateConversationActionBarComponent,
    ManageConversationActionBarComponent,
    NewConversationComponent,
    NewClientDepositComponent,
    NewPaymentComponent,
    ClientSelectorComponent,
    ClientSelectorResultsListComponent,
    ClientListItemComponent,
    PaymentRequestComponent,
    WatchersComponent,
    WatcherContextMenuComponent,
    NotificationsListComponent,
    NotificationsListItemComponent,
    NotificationListControlsComponent,
    LightswitchComponent,
    CheckboxCircleComponent,
    UserChangerListComponent,
    DialogNoPracticesComponent,
    DialogNoConnectionComponent,
    ConversationResolverComponent,
    DropdownIconComponent,
    PageTitleComponent,
    CreateActionsComponent,
    PaymentRequestFormComponent,
    CashRegisterIconComponent,
    CampaignAddFormComponent,
    SearchBarComponent,
    ConversationBulkActionsComponent,
    WhatsNewDialogComponent,
    DialogTemplateSelectorComponent,
    BlockSenderComponent,
    SplitPanelButtonComponent,
    VerticalSplitComponent,
    ProductRequestHistoryComponent,
    FilterItemComponent,
    FilterItemMultipleComponent,
    FilterCreatedAtComponent,
    FilterStatusComponent,
    FilterClientComponent,
    FiltersComponent,
    FilterItemTypeaheadComponent,
    FilterOwnerComponent,
    FilterApprovalStatusComponent,
    ToastsComponent,
    ToastSaveConversationPmsErrorComponent,
    TagAvatarComponent,
    ClientDetailsComponent,
    ClientPanelComponent,
    ClientActionsComponent,
    ClientNotRecognisedComponent,
    NoClientPanelComponent,
    FinancialNotesComponent,
    ClientNotesComponent,
    PaymentHistoryComponent,
    FilterActiveDepositComponent,
    NewClientDepositComponent,
    TopBarComponent,
    ReplacePlaceholderComponent,
    DialogMessagePreviewComponent,
    FormSelectorComponent,
    ConversationMessagePreviewComponent,
    ThemeIconUrlPipe,
    ThemeSvgColorPipe,
    FormRequestComponent,
    FormSubmissionListComponent,
    FormSubmissionListItemComponent,
    MessageButtonComponent,
    SafeUrlPipe,
  ],
  imports: [
    ButtonModule,
    CardModule,
    CalendarModule,
    CommonModule,
    CheckboxModule,
    SelectButtonModule,
    DropdownModule,
    DirectivesModule,
    InputTextModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    TabViewModule,
    ToastModule,
    ContextMenuModule,
    PasswordModule,
    SplitButtonModule,
    DateFnsModule,
    ListboxModule,
    InputNumberModule,
    InputSwitchModule,
    PushPipe,
    LetDirective,
    FormsModule,
    RouterModule,
    ProgressSpinnerModule,
    DialogModule,
    DynamicDialogModule,
    RadioButtonModule,
    TooltipModule,
    FileUploadModule,
    UtilsModule,
    JoyrideModule,
    QuillModule.forRoot({
      customModules: [
        {
          implementation: MagicUrl,
          path: 'modules/magicUrl'
        }
      ]
    }),
    ReactiveFormsModule,
    DragDropModule,
  ],
  exports: [
    CardModule,
    CalendarModule,
    ButtonModule,
    CheckboxModule,
    PatientSelectorComponent,
    PatientListItemComponent,
    ContextMenuModule,
    SelectButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    TabViewModule,
    ToastModule,
    PasswordModule,
    SplitButtonModule,
    UserAvatarComponent,
    CapsuleComponent,
    ListboxModule,
    InputNumberModule,
    InputSwitchModule,
    Message,
    MessageStatusComponent,
    UserChangerComponent,
    NavigationComponent,
    ProgressSpinnerModule,
    ConversationResolverComponent,
    NotificationsListComponent,
    TooltipModule,
    FileUploadModule,
    DialogModule,
    DynamicDialogModule,
    RadioButtonModule,
    DialogNoPracticesComponent,
    MobileHeaderComponent,
    DesktopHeaderComponent,
    NewConversationComponent,
    NewClientDepositComponent,
    NewPaymentComponent,
    ClientSelectorComponent,
    PaymentRequestComponent,
    WatchersComponent,
    DialogNoPracticesComponent,
    DialogNoConnectionComponent,
    QuillModule,
    DropdownIconComponent,
    PageTitleComponent,
    CreateActionsComponent,
    PaymentRequestFormComponent,
    CashRegisterIconComponent,
    CampaignAddFormComponent,
    SearchBarComponent,
    ConversationBulkActionsComponent,
    WhatsNewDialogComponent,
    DialogTemplateSelectorComponent,
    ClientAvatarComponent,
    BlockSenderComponent,
    SplitPanelButtonComponent,
    ProductRequestHistoryComponent,
    FilterItemComponent,
    FilterItemMultipleComponent,
    DragDropModule,
    FilterCreatedAtComponent,
    FilterStatusComponent,
    FilterClientComponent,
    FiltersComponent,
    FilterOwnerComponent,
    ToastsComponent,
    TagAvatarComponent,
    ClientDetailsComponent,
    ClientPanelComponent,
    ClientActionsComponent,
    ClientNotRecognisedComponent,
    NoClientPanelComponent,
    FinancialNotesComponent,
    ClientNotesComponent,
    PaymentHistoryComponent,
    DropdownIconComponent,
    DialogMessagePreviewComponent,
    FormSelectorComponent,
    TopBarComponent,
    DialogMessagePreviewComponent,
    ThemeIconUrlPipe,
    ThemeSvgColorPipe,
    SafeUrlPipe,
    FormSubmissionListComponent,
    FormSubmissionListItemComponent
  ],
  providers:[ConversationService]
})
export class UiModule { }
